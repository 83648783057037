import '@splidejs/splide/dist/css/splide.min.css';
import Splide from '@splidejs/splide';

export default class Logos {

    constructor() {
        this.onReady = this.onReady.bind(this);
    }

    init() {
        document.addEventListener('DOMContentLoaded', this.onReady);
    }

    onReady() {
        const block = document.querySelector('.logos');

        if(!block)  {
            return;
        }

        let options = {
            type   : 'loop',
            perPage: 6,
            perMove: 1,
            autoplay: true,
            lazyload: true,
            gap: 60,
            breakpoints: {
                568: {
                    perPage: 1,
                },
                992: {
                    perPage: 3,
                    gap: 30,
                }
            }
        };

        const displayInSidebar = block.classList.contains('logos--sidebar');

        /* Override some properties for sidebar */
        if(displayInSidebar) {
            options = {
                ...options,
                perPage: 2,
                perMove: 2,
                gap: 15,
                breakpoints: {},
                arrows: false
            };
        }

        new Splide( '.logos__list--slider' , options ).mount();
    }
}
