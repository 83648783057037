import FbIframe from "./components/fb-iframe";
import { Header } from "./components/header";
import MenuMobile from "./components/menu-mobile";
import News from "./blocks/news";
import Loader from "./components/loader";
import Events from "./blocks/events";
import Logos from "./blocks/logos";

/* Components */
(new Loader()).init();
(new Header()).init();
(new MenuMobile()).init();
(new FbIframe()).init();

/* Blocks */
(new News()).init();
(new Events()).init();
(new Logos()).init();