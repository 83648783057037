export default class Loader {
    init() {
        document.addEventListener('DOMContentLoaded', this.onReady);

        document.body.addEventListener('loader-show', this.onShow);
        document.body.addEventListener('loader-hide', this.onHide);
    }

    onReady() {
        const loader = document.createElement('div');
        loader.classList.add('loader');

        document.body.appendChild(loader);
    }

    onShow() {
        document.body.classList.add('loading');
    }

    onHide() {
        document.body.classList.remove('loading');
    }
}