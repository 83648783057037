import Calendar from "color-calendar";

import "color-calendar/dist/css/theme-basic.css";

export default class Events {

    constructor() {
        this.onReady = this.onReady.bind(this);

        this.calendarSelector = '#events-calendar';
        this.apiBaseUrl = `${window.location.origin}/wp-json/public-unit/events/`;
        this.isCalendarUpdatedAtInit = false;
    }

    init() {
        document.addEventListener('DOMContentLoaded', this.onReady);
    }

    onReady() {
        const calendar = document.querySelector(this.calendarSelector);

        if(!calendar) {
            return;
        }

        new Calendar({
            id: this.calendarSelector,
            layoutModifiers: ['month-left-align'],
            startWeekday: 1,
            customWeekdayValues: [
                'Nie', 'Pon', 'Wto', 'Śro', 'Czw', 'Pio', 'Sob'
            ],
            eventsData: this.getEventsData(),
            monthChanged: (currentDate) => {
                if(!this.isCalendarUpdatedAtInit)  {
                    this.isCalendarUpdatedAtInit = true;
                    return;
                }

                let from = new Date(currentDate);
                let to = this.getDateWithOffset(from, 30);

                this.updateEventsList(from, to);
            }
          });
    }

    getDateWithOffset(date, daysOffset = 0) {
        date = new Date(date);

        date = date.setDate(date.getDate() + daysOffset);

        return new Date(date);

    }

    getEventsData() {
        const events = document.querySelectorAll('.events .item');

        if(!events) {
            console.warn('Invalid events selector');
            return [];
        }

        const eventsData = [];

        for (const event of events) {
            eventsData.push({
                id: event.getAttribute('data-id'),
                start: event.getAttribute('data-start'),
                end: event.getAttribute('data-end'),
                name: event.getAttribute('data-name'),
                href: event.getAttribute('href'),
            });
        }

        return eventsData;
    }

    async updateEventsList(dateFrom, dateTo) {
        const eventList = document.querySelector('.events__list');

        dateFrom = new Date(dateFrom);
        dateTo = new Date(dateTo);

        document.body.dispatchEvent(new Event('loader-show'));

        const request = await fetch(`${this.apiBaseUrl}?from=${this.getFormattedDate(dateFrom)}&to=${this.getFormattedDate(dateTo)}`);
        const data = await request.json();

        if(request.ok) {
            eventList.outerHTML = data.list;
        } else {
            console.error(request.statusText);
        }

        document.body.dispatchEvent(new Event('loader-hide'));

        eventList.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    getFormattedDate(date) {
        date = new Date(date);
        return [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
          ].join('-');
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
}