export default class News {

    constructor() {
        this.navSelector = '.news__nav';
        this.navItemSelector = '.news__nav button';
        this.newsWrapperSelector = '.news';
        this.newsLoopSelector = '.news__loop';
        this.apiBaseUrl = `${window.location.origin}/wp-json/public-unit/news/`;

        this.registerNavItemListeners = this.registerNavItemListeners.bind(this);
        this.onNavItemClick = this.onNavItemClick.bind(this);
    }

    init() {
        document.addEventListener('DOMContentLoaded', this.registerNavItemListeners);
    }

    registerNavItemListeners() {
        const navItems = document.querySelectorAll(this.navItemSelector);

        for (const item of navItems) {
            item.addEventListener('click', this.onNavItemClick);
        }
    }

    async onNavItemClick(e) {
        const newsWrapper = document.querySelector(this.newsWrapperSelector);
        const newsLoop = document.querySelector(this.newsLoopSelector);
        const newsNav = document.querySelector(this.navSelector);

        const catId = e.target.getAttribute('data-id') || null;

        const firstPostFeatured = newsWrapper.classList.contains('first-post-featured');

        if(!catId) {
            console.error('Invalid catId');
        }

        document.body.dispatchEvent(new Event('loader-show'));

        const request = await fetch(`${this.apiBaseUrl}?cat_id=${catId}&first_post_featured=${firstPostFeatured}`);
        const data = await request.json();

        if(request.ok) {
            newsLoop.innerHTML = data;
        } else {
            console.error(request.statusText);
        }

        document.body.dispatchEvent(new Event('loader-hide'));

        this.setActiveNavItem(e.target);
    }

    setActiveNavItem(item) {
        if(!item) return;

        const navItems = document.querySelectorAll(this.navItemSelector);

        for (const item of navItems) {
            item.parentElement.classList.remove('active');
        }

        item.parentElement.classList.add('active');
    }


}
