export class Header {
    constructor() {
        this.updateScrollValues();
    }

    init() {
        window.addEventListener('scroll', () => {
            this.onScroll();
        });

        document.addEventListener('DOMContentLoaded', () => {
            this.onScroll();
            this.setBodyOffset();
        });

        window.addEventListener('resize', this.setBodyOffset);
    }

    onScroll() {
        const header = document.querySelector('.main-header');

        if(!header) {
            return;
        }
        
        if(this.lastScrollValue >= window.scrollY || this.lastScrollValue < 1) {
            document.body.classList.add('header-visible');
            header.classList.remove('hidden');
        } else {
            document.body.classList.remove('header-visible');
            header.classList.add('hidden');
        }

        if(window.scrollY > 1) {
            document.body.classList.add('header-scrolled');
            header.classList.add('scrolled');
        } else {
            document.body.classList.remove('header-scrolled');
            header.classList.remove('scrolled');
        }
        
        this.updateScrollValues();
    }

    updateScrollValues() {
        this.lastScrollValue = window.scrollY;
        this.scrolled = window.scrollY > 0;
    }

    setBodyOffset = () => {
        const header = document.querySelector('.main-header');

        if(!header) {
            return;
        }

        const height = header.getBoundingClientRect().height;

        if(this.isHomePage()) {
            const hero = this.getHero();

            if(!hero) {
                document.body.style.paddingTop = height + 'px';
                return;
            }

            hero.style.paddingTop = height + 'px';
            return;
        }

        document.body.style.paddingTop = height + 'px';
    }

    isHomePage = () => {
        return document.body.classList.contains('home');
    }

    getHeader = () => {
        return document.querySelector('.main-header');
    }

    getHero = () => {
        return document.querySelector('.home .entry-content .featured-post');
    }
}
