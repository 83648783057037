export default class FbIframe {
    constructor() {
        this.onReady = this.onReady.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    init() {
        document.addEventListener('DOMContentLoaded', this.onReady);
    }

    onReady() {
        const btn = document.querySelector('.fb-iframe button');

        if(!btn) {
            return;
        }

        btn.addEventListener('click', this.toggle);
    }

    toggle() {
        const iframe = document.querySelector('.fb-iframe');

        if(!iframe) {
            return;
        }

        iframe.classList.toggle('active');
    }
}