export default class MenuMobile {

    constructor() {
        this.menuSelector = '.menu-mobile';
        this.btnSelector = '.menu-mobile__button';
        this.menuContentSelector = '.menu-mobile__content';
        this.menuLinkToggleSelector = '.menu-item-toggle';
        this.mainHeaderSelector = '.main-header';
        this.menuBottomSelector = '.menu-mobile__bottom';

        this.onReady = this.onReady.bind(this);
        this.toggleContent = this.toggleContent.bind(this);
        this.updateMenuContentHeight = this.updateMenuContentHeight.bind(this);
    }

    init() {
        document.addEventListener('DOMContentLoaded', this.onReady);
        document.body.addEventListener('gw-wcag-font-size-changed', this.updateMenuContentHeight);
        window.addEventListener('resize', this.updateMenuContentHeight);
    }

    onReady() {
        this.updateMenuContentHeight();

        this.getElement(this.btnSelector).addEventListener('click', this.toggleContent);

        for (const item of this.getElement(this.menuLinkToggleSelector, true)) {
            item.addEventListener('click', this.toggleSubmenu);
        }

    }

    getElement(selector, all = false) {
        if(!selector) {
            console.warn(`Invalid selector`);
            return false;
        }

        const el = all ? document.querySelectorAll(selector) : document.querySelector(selector);

        if(!el) {
            console.warn(`Element ${selector} not found.`);
            return false;
        }

        return el;
    }

    toggleContent() {
        const menu = this.getElement(this.menuSelector);
        const btn = this.getElement(this.btnSelector);

        if(!menu || !btn) {
            return false;
        }

        menu.classList.toggle('active');
        btn.classList.toggle('active');
        document.body.classList.toggle('overflow-hidden');
    }

    toggleSubmenu(e) {
        e.preventDefault();
        const btn = e.target;
        const link = btn.parentElement;
        const item = link.parentElement;
        
        item.classList.toggle('open');
        btn.classList.toggle('bx-flip-vertical');

    }

    updateMenuContentHeight() {
        const menuContent = this.getElement(this.menuContentSelector);
        const mainHeader =  this.getElement(this.mainHeaderSelector);
        const menuBottom =  this.getElement(this.menuBottomSelector);

        if(!menuContent || !mainHeader || !menuBottom) {
            return false;
        }

        const mainHeaderHeight = parseFloat(window.getComputedStyle(mainHeader, null).getPropertyValue('height'));
        const menuBottomHeight = parseFloat(window.getComputedStyle(menuBottom, null).getPropertyValue('height'));

        menuContent.style.height = window.innerHeight - mainHeaderHeight - menuBottomHeight + 'px';
        menuContent.style.bottom = menuBottomHeight + 'px';
    }
}